import { Component, computed, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent, TIcon } from '@shared/components/icon';
import { LoadingComponent, LoadingService } from '@shared/components/loading';
import { MenuComponent } from '@shared/components/menu';
import { AppNavigationService } from '@shared/services/app-navigation.service';
import { AppStarterService } from '@shared/services/app-starter.service';
import { AppStorageService } from '@shared/services/app-storage.service';
import { styles } from '@shared/services/app-styles.service';
import { AppService } from '@shared/services/app.service';
import { CustomMessageService } from '@shared/services/custom-message.service';
import { SitesService } from '@shared/services/sites.service';
import { UserService } from '@shared/services/user.service';
import { translations } from '@shared/utils/translations';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBar } from 'primeng/progressbar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';

@Component({
    selector: 'app-root',
    imports: [
        RouterOutlet,
        DropdownModule,
        FormsModule,
        TableModule,
        SelectButtonModule,
        IconComponent,
        MenuComponent,
        TranslateModule,
        ToastModule,
        LoadingComponent,
        ProgressBar,
    ],
    template: `
        <loading />
        <p-toast>
            <ng-template let-message pTemplate="message" let-severity>
                <div class="flex flex-row items-center justify-start w-full">
                    <icon
                        class="mr-item-sm"
                        [style.height]="styles.iconSize"
                        [name]="getIconName(severity.severity)"
                        customColor="white" />

                    {{ message.summary | translate }}
                </div>
            </ng-template>
        </p-toast>
        @if (loading()) {
            <div class="h-dvh flex flex-col justify-between items-center">
                <div class="flex-grow flex items-center justify-center">
                    <img src="assets/images/kone-logo-blue.png" width="250" alt="kone" />
                </div>
                <div class="mb-page flex flex-col items-center">
                    <div class="text-soft uppercase text-xs mb-1">
                        {{ translations.global.loading | translate }}...
                    </div>
                    <p-progressBar
                        class="block w-[225px]"
                        mode="indeterminate"
                        [style]="{ height: '4px' }" />
                </div>
            </div>
        } @else {
            <div class="wrapper" [class.logged-in]="isLoggedIn()">
                @if (isLoggedIn()) {
                    <app-menu />
                }
                <router-outlet />
            </div>
        }
    `,
    styles: `
        :host {
            display: flex;
            flex-direction: column;

            .wrapper {
                .logged-in {
                    top: var(--header-height);
                }
            }
        }
    `,
})
export class AppComponent {
    protected readonly translations = translations;
    protected readonly styles = styles;

    private appStarterService = inject(AppStarterService);
    private appService = inject(AppService);
    private userService = inject(UserService);
    private appNavigationService = inject(AppNavigationService);
    private appStorageService = inject(AppStorageService);
    private toastService = inject(CustomMessageService);
    private loadingService = inject(LoadingService);

    loading = inject(AppService).loading;
    user = inject(UserService).user;
    sites = inject(SitesService).sites;

    isLoggedIn = computed(() => this.userService.isLoggedIn() && this.sites().length > 0);

    constructor() {
        this.appStarterService.initServices().then(async hasError => {
            if (hasError) await this.appService.logout();
            this.appService.stopLoading();
            // this.loadingService.show();
            // setTimeout(() => {
            //     this.loadingService.hide();
            // }, 500);
            // this.toastService.success('test', 100000);
            // this.toastService.error('test', 100000);
            // this.toastService.info('test', 100000);
        });
    }

    getIconName(severity: string): TIcon {
        switch (severity) {
            case 'success':
                return 'check';
            case 'error':
                return 'alert-triangle';
            default:
                return 'info';
        }
    }
}
