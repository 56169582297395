import { inject, Injectable } from '@angular/core';
import { AppLanguageService } from '@shared/services/app-language.service';
import { AwsAmplifyService } from '@shared/services/aws-amplify.service';
import { HotkeysService } from '@shared/services/hotkeys.service';
import { SitesService } from '@shared/services/sites.service';
import { ThemeService } from '@shared/services/theme.service';
import { UserService } from '@shared/services/user.service';

@Injectable({
    providedIn: 'root',
})
export class AppStarterService {
    private themeService = inject(ThemeService);
    private appLanguageService = inject(AppLanguageService);
    private userService = inject(UserService);
    private hotKeys = inject(HotkeysService);
    private awsAmplifyService = inject(AwsAmplifyService);
    private sitesService = inject(SitesService);

    // true = has error
    // false = no error
    async initServices(): Promise<boolean> {
        this.themeService.fnInit();
        this.appLanguageService.fnInit();

        this.hotKeys
            .addShortcut({
                keyPlus: 'T',
            })
            .subscribe(() => {
                this.themeService.trigger();
            });

        const valid = await this.awsAmplifyService.validateAuthSession();

        if (!valid) return true;

        const response = await this.userService.setUserData();

        if (response.value) {
            this.userService.store.updateItem({
                isLoggedIn: true,
            });
        } else {
            return true; // hasError
        }

        await this.sitesService.fnInit();

        return false;
    }
}
