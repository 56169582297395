import { CanActivateFn } from '@angular/router';
import { TIcon } from '@shared/components/icon/icon.component';
import { TUserData } from '@shared/interfaces';

export type TPageType =
    | 'users'
    | 'sitedetails'
    | 'configurations'
    | 'help'
    | 'accessgroups'
    | 'statistics'
    | 'messages'
    | 'usersmanagement';

const availableConfigTabs = [
    'createsite',
    'faqlinks',
    'categories',
    'topics',
    'forceupdate',
    'timezones',
    'adintegration',
    'frontlines',
] as const;
export const configurationTabs = availableConfigTabs.map(tab => tab);
export type TConfigTab = (typeof availableConfigTabs)[number];

const availableSideDetailsTabs = [
    'basicinformation',
    'call-limits',
    'elevatorgroups',
    'allowed-lifts',
    'terminalsandzones',
    'acssync',
] as const;
export const siteDetailsTabs = availableSideDetailsTabs.map(tab => tab);
export type TSiteDetailTab = (typeof availableSideDetailsTabs)[number];

const availableUserMgmtTabs = ['usersoverview', 'usersoverview/details', 'roles'] as const;
export const userMgmtTabs = availableUserMgmtTabs.map(tab => tab);
export type TUserMgmtTab = (typeof availableUserMgmtTabs)[number];

export type TRouteAccess = {
    [key in TPageType]: IRouteAccessConfig;
};

export type TAvailablePath = TConfigTab | TSiteDetailTab | TUserMgmtTab;

export interface IPathConfig {
    path: TPageType;
    availablePaths?: IAvailablePathUserType[];
}

export interface IAvailablePathUserType {
    path: TAvailablePath;
    userTypes: TUserData[];
}

export type IRouteAccessConfig = {
    canActivate: Array<CanActivateFn | any>;
    userTypes: TUserData[]; // used to display items in app menu -> do not fill if not required in menu
    pathConfig: IPathConfig;
    url?: string;
    title: string;
    subtitle: string;
    icon: TIcon;
    visibleOnMenu: boolean;
    hidden?: boolean;
    disabled?: boolean;
};
